<template>
  <el-select v-model="value">
    <el-option :value="0" label="未选择"></el-option>
    <el-option :value="item.id" :label="item.title" v-for="(item,key) in list" :key="key"></el-option>
  </el-select>
</template>

<script>
export default {
  name: "YChooseScriptCate",
  model:{
    prop:"modelVal",
    event:'valChange'
  },
  props:{
    modelVal: {
      type:Number,
      default:0,
    },
  },
  data(){
    return{
      list:[],
      value:0,
    }
  },
  watch:{
    modelVal(){
      this.value = this.modelVal;
    },
    value(e){
      this.$emit('valChange',e);
    },
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$u.api.card.scriptCateSysAll().then(res=>{
        this.list = res;
      })
    }
  }
}
</script>

<style scoped>

</style>