<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list" border>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="cate_id" label="所属分类编号"></el-table-column>
        <el-table-column prop="content" label="内容"></el-table-column>
        <el-table-column label="操作" align="center">
          <template #header>
            <el-button @click="editFormBox=true" type="primary">添加</el-button>
          </template>
          <template #default="s">
            <el-button @click="editItem(s.row)" type="primary">编辑</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="编辑" :visible.sync="editFormBox" @close="editFormClose">
        <el-form label-width="5rem" style="padding: 0 2rem">
          <el-form-item label="所属分类">
            <y-choose-script-cate v-model="form.cate_id"></y-choose-script-cate>
          </el-form-item>
          <el-form-item label="内容">
            <el-input v-model="form.content"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editFormBox = false">取 消</el-button>
          <el-button type="primary" @click="edit">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>

import YChooseScriptCate from "@/components/card/y-choose-script-cate/YChooseScriptCate";
export default {
  name: "list",
  components:{
    YChooseScriptCate

  },
  data() {
    return {
      list:[],
      form:{
        content:"",
        cate_id: 0,
      },
      editFormBox:false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del(item){
      this.$u.api.card.scriptItemSysDel({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
      })
    },
    editItem(item){
      this.form = {...item};
      this.editFormBox = true;
    },
    editFormClose(){
      this.form = {
        content:"",
        cate_id: 0,
      }
      this.editFormBox = false;
    },
    load() {
      this.$u.api.card.scriptItemSysAll({cate_id:0}).then(res=>{
        this.list = res;
      })
    },
    edit(){
      this.$u.api.card.scriptItemSysEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>